<template>
    <div class="h-100-vh">
        <div class="card " v-if=" !loading.data">
            <div class="card-header bg-white">
                <div class="row" v-if="questionInformation.product.id">
                    <div class="col-12 mb-3">
                        <h5 v-text="questionInformation.product.name">
                        </h5>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-lg-3">
                                <p>
                                    <strong class="f-w-600" v-text="$t('tables.sku') + ': '"></strong>
                                    <span v-text="questionInformation.product.sku "></span>
                                </p>
                            </div>
                            <div class="col-6 col-lg-3 text-right text-lg-left">
                                <p>
                                    <strong class="f-w-600" v-text="$t('tables.price') + ': '"></strong>
                                    <span
                                        v-text=" fnFormatPrice(questionInformation.product.price , questionInformation.product.currency) "></span>
                                </p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <p>
                                    <strong class="f-w-600" v-text="$t('tables.quantity') + ': '"></strong>
                                    <span v-text="questionInformation.product.quantity"></span>
                                </p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <p class="text-right">
                                    <button class="btn btn-link p-0" @click="showVariants = !showVariants"
                                        v-text=" showVariants ? $t('messages.hideVariants') : $t('messages.showVariants') "
                                        v-if=" questionInformation.product.variants && questionInformation.product.variants.length > 0">
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <table class="table ecart-table table-striped table-sm text-center animated fadeIn"
                            v-show="showVariants">
                            <thead>
                                <tr>
                                    <th v-text="$t('tables.name')"></th>
                                    <th v-text="$t('tables.price')"></th>
                                    <th v-text="$t('tables.sku')"></th>
                                    <th v-text="$t('tables.quantity')"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for=" (productVariant,index) in questionInformation.product.variants"
                                    :key="index">
                                    <td v-text="productVariant.name || '' "></td>
                                    <td
                                        v-text="fnFormatPrice(productVariant.price , questionInformation.product.currency) || '' ">
                                    </td>
                                    <td v-text="productVariant.sku || '' "></td>
                                    <td v-text="productVariant.quantity || '' "></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h5 class="text-warning" v-else>
                    <strong>
                        <i class="fa fa-info-circle mr-1"></i> <span v-text="$t('messages.productNoExist')"></span>
                    </strong>
                </h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4 text-center mb-4 mb-lg-0">
                        <img v-image="questionInformation.product.imageUrl" class="img-fluid border" alt="">
                    </div>
                    <div class="col-12 col-lg-8 d-flex flex-column justify-content-between ">
                        <div>
                            <p class="f-30" v-text=' questionInformation.question'>
                            </p>
                            <v-observer ref="questionAnswer" tag="form" class="animated fadeIn form-rounded-inputs"
                                @submit.prevent="fnValidateData()" v-slot="{ invalid }"
                                v-if=" !questionInformation.answer">
                                <div class="row align-items-center justify-content-end ">
                                    <div class="col-12">
                                        <v-validation rules="required|min:2" v-slot="{ errors }"
                                            :name="$t('general.form.answer')">
                                            <textarea cols="30" rows="7" class="w-100 form-control mb-2 answer-area"
                                                :placeholder="$t('general.form.answer') + '...'"
                                                :class="{ 'is-invalid': errors.length > 0 }"
                                                v-model="questionAnswer"></textarea>
                                            <div class="invalid-feedback" v-if="errors.length > 0">
                                                {{ errors[0] }}
                                            </div>
                                        </v-validation>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn h-100" :disabled=" loading.sendingData "
                                            :class=" ( !invalid) ? 'btn-success': 'btn-light' ">
                                            <span v-text="$t('general.button.answer')"
                                                v-if="!loading.sendingData"></span>
                                            <i class="fa fa-spinner fa-spin" v-else></i>
                                        </button>
                                    </div>
                                </div>
                            </v-observer>
                            <h5 class="mb-3 text-muted" v-else>
                                <strong v-text="$t('general.form.answer') + ': ' "></strong>
                                <span v-text="questionInformation.answer"></span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <no-data v-else :isLoading="loading.data" :dataMessage="$t('dashboard.stores.integration.message')"
            :btnMessage=" $t('general.newStore') ">
        </no-data>

        <!-- <pre> {{ questionInformation }} </pre> -->
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        data() {
            return {
                showVariants: false,
                questionAnswer: null,
            }
        },
        computed: {
            ...mapState('EcartQuestion', ['questionInformation', 'loading']),
        },
        methods: {
            ...mapActions('EcartQuestion', ['fnApiGetQuestion', 'fnApiAnswerQuestion']),
            async fnValidateData() {
                if (await this.$refs['questionAnswer'].validate()) {
                    this.fnApiAnswerQuestion(this.questionAnswer);
                }
            }
        },
        mounted() {
            this.fnApiGetQuestion(this.$route.params.id);
        }
    }
</script>

<style>

</style>